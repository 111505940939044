@font-face {
  font-family: 'Cera Stencil Pro';
  src: url('./fonts/CeraStencilPRO-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'SohoGothicPro-Bold';
  src: url('./fonts/SohoGothicPro-Bold.otf') format('truetype');
}

@font-face {
  font-family: 'SohoGothicPro-BoldItalic';
  src: url('./fonts/SohoGothicPro-BoldItalic.otf') format('truetype');
}

@font-face {
  font-family: 'SohoGothicPro-Italic';
  src: url('./fonts/SohoGothicPro-Italic.otf') format('truetype');
}

@font-face {
  font-family: 'SohoGothicPro-Medium';
  src: url('./fonts/SohoGothicPro-Medium.otf') format('truetype');
}

@font-face {
  font-family: 'SohoGothicPro-MediumItalic';
  src: url('./fonts/SohoGothicPro-MediumItalic.otf') format('truetype');
}

@font-face {
  font-family: 'SohoGothicPro-Regular';
  src: url('./fonts/SohoGothicPro-Regular.otf') format('truetype');
}

@layer utilities {
  /* Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

html {
  font-family: 'SohoGothicPro-Regular';
  font-size: 13px;
  
}

// /* Hide native scrollbar */
.scroll-content::-webkit-scrollbar {
  width: 0.5em;
}

.scroll-content::-webkit-scrollbar-track {
  background: transparent;
}

.scroll-content::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.fitness-first-bold {
  font-family: 'SohoGothicPro-Bold'
}

.fitness-first-bold-italic {
  font-family: 'SohoGothicPro-BoldItalic';
}

.fitness-first-medium {
  font-family: 'SohoGothicPro-Medium';
}

.fitness-first-medium-italic {
  font-family: 'SohoGothicPro-MediumItalic';
}

.celebrity-fitness-heading {
  font-family: 'Cera Stencil Pro'
}



.spin {
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}